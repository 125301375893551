import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allEarnings: [],
  allAutoTracked: [],
  allPendingEarnings: [],
  allTrackedEarnings: [],
  earningDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
  totalAmountGotFromPartner: 0,
  totalAmountGotPending: 0,
  totalAmountGotCancelled: 0,
  totalAmountGotConfirmed: 0,
  totalAmountToGiveToUser: 0,
  totalAmountToGivePending: 0,
  totalAmountToGiveCancelled: 0,
  totalAmountToGiveConfirmed: 0,
  statByStore: [],
};
const defaultPath = "/earnings";

export const fetchAllAutoTracked = createAsyncThunk(
  "fetch_all_auto_tracked",
  async (queryParams) => {
    try {
      const params = [
        `page=${queryParams?.page ?? 1}`,
        `pageSize=${queryParams?.pageSize ?? 10}`,
        `search=${queryParams?.search ?? ""}`,
        `startDate=${queryParams?.startDate ?? ""}`,
        `endDate=${queryParams?.endDate ?? ""}`,
        `status=${queryParams?.status ?? ""}`,
        `store=${queryParams?.store ?? ""}`,
        `affiliation=${queryParams?.affiliation ?? ""}`,
        `amount=${queryParams?.amount ?? ""} `,
        `noOfOrders=${queryParams?.noOfOrders ?? ""}`,
        `sale=${queryParams?.sale ?? ""}`,
        `user=${queryParams?.user ?? ""}`,
      ].join("&");

      const url = `${defaultPath}/all-auto-tracked?${params}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllEarnings = createAsyncThunk(
  "fetch_earnings",
  async (queryParams) => {
    try {
      const params = [
        `page=${queryParams?.page ?? 1}`,
        `pageSize=${queryParams?.pageSize ?? 10}`,
        `search=${queryParams?.search ?? ""}`,
        `userId=${queryParams?.userId ?? ""}`,
        `orderId=${queryParams?.orderId ?? ""}`,
        `advId=${queryParams?.advId ?? ""}`,
        `remarks=${queryParams?.remarks ?? ""}`,
        `startDate=${queryParams?.startDate ?? ""}`,
        `endDate=${queryParams?.endDate ?? ""}`,
        `status=${queryParams?.status ?? ""}`,
        `store=${queryParams?.store ?? ""}`,
        `affiliation=${queryParams?.affiliation ?? ""}`,
        //`amount=${queryParams?.amount ?? ""} `,
        //`noOfOrders=${queryParams?.noOfOrders ?? ""}`,
        //`sale=${queryParams?.sale ?? ""}`,
        //`user=${queryParams?.user ?? ""}`,
      ].join("&");

      const url = `${defaultPath}/all-earnings?${params}`;
      const { data } = await API.get(url);

      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllTrackedEarnings = createAsyncThunk(
  "fetchTrackedEarnings",
  async (queryParams) => {
    try {
      const params = [
        `page=${queryParams?.page ?? 1}`,
        `pageSize=${queryParams?.pageSize ?? 10}`,
        `search=${queryParams?.search ?? ""}`,
        `userId=${queryParams?.userId ?? ""}`,
        `orderId=${queryParams?.orderId ?? ""}`,
        `advId=${queryParams?.advId ?? ""}`,
        `remarks=${queryParams?.remarks ?? ""}`,
        `startDate=${queryParams?.startDate ?? ""}`,
        `endDate=${queryParams?.endDate ?? ""}`,
        `status=${queryParams?.status ?? ""}`,
        `store=${queryParams?.store ?? ""}`,
        `affiliation=${queryParams?.affiliation ?? ""}`,
        //`amount=${queryParams?.amount ?? ""} `,
        //`noOfOrders=${queryParams?.noOfOrders ?? ""}`,
        //`sale=${queryParams?.sale ?? ""}`,
        //`user=${queryParams?.user ?? ""}`,
      ].join("&");

      const url = `${defaultPath}/pre-approved/all-earnings?${params}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createEarnings = createAsyncThunk(
  "createEarnings",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateEarnings = createAsyncThunk(
  "updateEarnings",
  async ({ earningId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${earningId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const trackedToConfirmEarning = createAsyncThunk(
  "trackedToConfirmEarning",
  async (earningId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/tracked-for-confirm/${earningId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const trackedToCancelEarning = createAsyncThunk(
  "trackedToCancelEarning",
  async (earningId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/tracked-for-cancel/${earningId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

// export const confirmEarning = createAsyncThunk(
//   "confirmEarning",
//   async (earningId) => {
//     try {
//       const { data } = await API.patch(
//         `${defaultPath}/update/confirm/${earningId}`
//       );
//       return data;
//     } catch (error) {
//       if (error?.response?.status == 401) {
//         localStorage.clear();
//         sessionStorage.clear();
//       }
//       toast.custom(
//         <Danger message={error?.response?.data?.errors || error.message} />
//       );
//     }
//   }
// );
//
export const confirmEarning = createAsyncThunk(
  "confirmEarnings",
  async (earningIds) => {
    console.log(earningIds, "earning ids in the response");
    try {
      const { data } = await API.patch(`${defaultPath}/update/confirm`, {
        earningIds,
      });
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const cancelBulkEarning = createAsyncThunk(
  "cancelBulkEarning",
  async (earningIds) => {
    console.log(earningIds, "earning ids in the response");
    try {
      const { data } = await API.patch(`${defaultPath}/update/cancel-bulk`, {
        earningIds,
      });
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);


export const cancelEarning = createAsyncThunk(
  "cancelEarning",
  async (earningId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update/cancel/${earningId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchEarningDetails = createAsyncThunk(
  "fetch_earning_details",
  async (earningId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${earningId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteEarning = createAsyncThunk(
  "deleteEarnings",
  async (earningId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${earningId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const earningSlice = createSlice({
  name: "stories",
  initialState,
  extraReducers: (builder) => {
    // fetch all auto tracked
    builder.addCase(fetchAllAutoTracked.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAutoTracked.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allAutoTracked = action.payload.allEarnings;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });

    // fetch all earnings
    builder.addCase(fetchAllEarnings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllEarnings.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        console.log(action.payload, "actionpayload");
        state.allEarnings = action.payload.allEarnings;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
        state.totalAmountGotFromPartner =
          action.payload.totalAmountGotFromPartner;
        state.totalAmountGotPending = action.payload.totalAmountGotPending;
        state.totalAmountGotCancelled = action.payload.totalAmountGotCancelled;
        state.totalAmountGotConfirmed = action.payload.totalAmountGotConfirmed;
        state.totalAmountToGiveToUser = action.payload.totalAmountToGiveToUser;
        state.totalAmountToGivePending =
          action.payload.totalAmountToGivePending;
        state.totalAmountToGiveCancelled =
          action.payload.totalAmountToGiveCancelled;
        state.totalAmountToGiveConfirmed =
          action.payload.totalAmountToGiveConfirmed;
        state.statByStore = action.payload.statByStore;
      }
    });

    // fetch all earnings
    builder.addCase(fetchAllTrackedEarnings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTrackedEarnings.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allTrackedEarnings = action.payload.allEarnings?.allEarnings;
        state.page = action.payload.allEarnings?.page;
        state.pages = action.payload.allEarnings?.pages;
        state.pageSize = action.payload.allEarnings?.pageSize;
      }
    });

    // create earnings
    builder.addCase(createEarnings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createEarnings.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"successfully created new earnings"} />);
      }
    });

    // update status to cancel
    builder.addCase(cancelEarning.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(cancelEarning.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully cancelled the  earnings"} />
        );
      }
    });

    // update status to confirm
    builder.addCase(confirmEarning.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(confirmEarning.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully confirmed the earnings"} />
        );
      }
    });

    builder.addCase(cancelBulkEarning.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(cancelBulkEarning.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully cancelled the  earnings"} />
        );
      }
    });

    // delete earnings
    builder.addCase(deleteEarning.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteEarning.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allEarnings = state.allEarnings.filter(
          (item) => item._id !== action.payload.earningId
        );
        state.allAutoTracked = state.allAutoTracked.filter(
          (item) => item._id !== action.payload.earningId
        );
        toast.custom(<Success message={" earning deleted successfully "} />);
      }
    });

    // cancel earnings
    builder.addCase(trackedToCancelEarning.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(trackedToCancelEarning.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allEarnings = state.allEarnings.filter(
          (item) => item._id !== action.payload.earningId
        );
        toast.custom(
          <Success
            message={
              "successfully updated earning status to tracked to cancel  earnings"
            }
          />
        );
      }
    });

    // approve earnings
    builder.addCase(trackedToConfirmEarning.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(trackedToConfirmEarning.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allEarnings = state.allEarnings.filter(
          (item) => item._id !== action.payload.earningId
        );
        toast.custom(
          <Success
            message={
              "successfully updated earning status to tracked to confirm  earnings"
            }
          />
        );
      }
    });

    // update  earnings
    builder.addCase(updateEarnings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateEarnings.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"earning details updated successfully"} />
        );
      }
    });

    // fetch  stories details
    builder.addCase(fetchEarningDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchEarningDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.earningDetails = action.payload.earningDetails;
      }
    });
  },
});

export const earningsReducer = earningSlice.reducer;
